import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row } from "react-bootstrap"

import * as styles from "../../styles/components/Careers/careers.module.scss"

const OpenPositions = () => {
  return (
    <Row className="mt-3 pt-5 p-sm-5 px-3 px-sm-5 border shadow position-relative">
      <div className="py-5 px-sm-5">
        <div className={styles.ribbon}>
          <p>Open Positions</p>
        </div>
        <h1 className={`${styles.title}`}>
          Join California’s Most Diversified Pediatric Medical Group
        </h1>
        <h2 className="fs-1 mb-3">
          Are you a pediatrician with a passion for challenging the status quo
          and transforming the way children receive healthcare in Sri Lanka? We
          want you on our team!
        </h2>
        <p>
          Are you a seasoned pediatrician with the expertise to build a dynamic
          framework for managing medical professionals, and the vision to
          cultivate a community of devoted and enthusiastic pediatricians
          committed to making a lasting difference in the lives of Sri Lankan
          children? If so, this opportunity is tailor-made for you.{" "}
        </p>
        <p>
          We are seeking an exceptional individual to join our team and take the
          reins in shaping a thriving network of pediatricians who prioritize
          the health and well-being of children across the nation. In this role,
          you will not only be a leader in the field of pediatric medicine but
          also an agent of change, spearheading initiatives that inspire your
          colleagues and improve the standard of pediatric care in Sri Lanka.{" "}
        </p>
        <p>
          We’re the largest pediatric medical group in LA with over 20 clinics
          in California, we will provide the financial support and resources to
          help you make this vision a reality. And with a competitive salary and
          flexible compensation model, plus the opportunity to earn
          certification from Kids & Teens Medical Group Los Angeles, you can
          confidently embark on a rewarding career in Sri Lanka.
        </p>

        <div className="my-3 my-sm-5">
          <h3 className="fs-3 my-3">Requirements</h3>
          <ul className="fs-4">
            <li>Proven experience as a pediatrician</li>
            <li>Medical Degree with specialization in pediatrics</li>
            <li>Valid license to practice medicine</li>
          </ul>
        </div>

        <div className="my-3 my-sm-5">
          <h3 className="fs-3 my-3">Benefits</h3>
          <ul className="fs-4">
            <li>Access to advanced medical technology and equipment</li>
            <li> Access to world-renowned children's hospitals in LA</li>
            <li>Opportunity to log in and listen to the latest treatments</li>
            <li>
              Shared administrative responsibilities to increase efficiency
            </li>
            <li>Greater flexibility to balance work and personal life</li>
            <li>Opportunities for growth and advancement </li>
            <li>Comprehensive benefits package</li>
          </ul>
        </div>

        <p className="">
          Interviews will start soon, so apply before May 21<sup>st</sup> 2023
          for consideration or schedule a personal interview with the principal
          from the USA. If you're a passionate pediatrician who wants to make a
          difference, please send your application via email to
          <a
            className={styles.weblinks}
            href="mailto: kidsandteens@ktdoctor.com.com"
          >
            {" "}
            kidsandteens@ktdoctor.com
          </a>{" "}
          or apply through{" "}
          <a
            className={styles.weblinks}
            href="https://www.ktdoctor.com/en/careers/"
          >
            www.ktdoctor.com/careers
          </a>
          .
        </p>

        <StaticImage
          src="../../images/ktmg-group-logos.png"
          alt="patient care"
          className="my-3 my-sm-5 d-flex"
          style={{ maxWidth: "925px" }}
        />

        <p>
          Kids & Teens Medical Group No: 139, Dharmapala Mawatha, Colombo 07.
        </p>
        <p>
          Email:{" "}
          <a
            className={styles.weblinks}
            href="mailto: kidsandteens@ktdoctor.com.com"
          >
            {" "}
            kidsandteens@ktdoctor.com
          </a>{" "}
          | Web:{" "}
          <a
            className={styles.weblinks}
            href="https://www.ktdoctor.lk"
            target="_blank"
          >
            www.ktdoctor.lk
          </a>
        </p>
        <p>
          FB:{" "}
          <a
            className={styles.weblinks}
            href="https://www.facebook.com/kidsandteensmedicalgroup"
            target="_blank"
          >
            kidsandteensmedicalgroup{" "}
          </a>
          | IG:{" "}
          <a
            className={styles.weblinks}
            href="https://www.instagram.com/ktmedicalgroup/"
            target="_blank"
          >
            ktmedicalgroup
          </a>{" "}
        </p>
      </div>
    </Row>
  )
}

export default OpenPositions
