import React from "react"
import { Container, Row, Col, Button, Accordion } from "react-bootstrap"
import { Link } from "gatsby-plugin-react-intl"
import Title from "../title"
import OpenPositions from "./openPositions"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl } from "gatsby-plugin-react-intl"

import * as styles from "../../styles/components/Careers/careers.module.scss"

export default function PatientCare() {
  const intl = useIntl()
  return (
    <div>
      <Title title={intl.formatMessage({ id: "PatientCare" })} />
      <Container fluid>
        <Row xs={1} md={2}>
          <Col className={styles.wrapper}>
            <div className={`${styles.content} p-5 mx-lg-5`}>
              <h1 className={`${styles.title} }`}>
                {intl.formatMessage({ id: "PatientCareTitle1" })}
              </h1>
              <p className={`${styles.text} my-4 `}>
                {intl.formatMessage({ id: "PatientCare1" })}
              </p>
            </div>
          </Col>

          <Col className="p-0">
            <StaticImage
              src="../../images/careers.jpg"
              layout="full_width"
              className={styles.img}
              alt=""
            />
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row md={2} className="mb-5 py-5 g-5 align-items-center">
          <Col className="px-5">
            <h3 className={`${styles.title2} mb-5`}>
              {intl.formatMessage({ id: "PatientCareTitle2" })}
            </h3>
            <p>
              <em>{intl.formatMessage({ id: "PatientCare2" })}</em>
            </p>
            <p>
              <em>{intl.formatMessage({ id: "PatientCare3" })}</em>
            </p>
            <p>
              <em>{intl.formatMessage({ id: "PatientCare4" })}</em>
            </p>
          </Col>

          <Col className="px-5 text-center">
            <StaticImage
              src="../../images/patient-care-2.webp"
              alt="patient care"
              layout="fixed"
              className="mx-auto mb-3"
            />

            <Link to="/locations">
              <Button variant="warning" size="lg" className={styles.btn}>
                {intl.formatMessage({ id: "Locations" })}
              </Button>
            </Link>
          </Col>
        </Row>

        {/* Currently open positions */}

        <OpenPositions />

        {/* Information Section */}

        <Row xs={1} md={2} className="my-3 my-sm-5 py-5 g-5 align-items-center">
          <Col className="px-5">
            <h3 className={`${styles.title2} mb-5`}>
              Pediatrics MD, DO, NP, or PA
            </h3>
            <p>
              {intl.formatMessage({ id: "PatientCare5" })}- MD, DO, NP, and PA.
              <ul>
                <li>{intl.formatMessage({ id: "PatientCarePosition1" })}</li>

                <li>{intl.formatMessage({ id: "PatientCarePosition2" })}</li>

                <li>{intl.formatMessage({ id: "PatientCarePosition3" })}</li>

                <li>{intl.formatMessage({ id: "PatientCarePosition4" })}</li>
              </ul>
            </p>

            <p>
              {intl.formatMessage({ id: "PatientCare6" })}
              <a
                className={styles.weblinks}
                href="mailto: kidsandteens@ktdoctor.com.com"
              >
                {" "}
                kidsandteens@ktdoctor.com
              </a>
              .
            </p>
          </Col>

          <Col className="px-5">
            <div className={styles.purple}>
              <p>{intl.formatMessage({ id: "PatientCare7" })}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
