import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Career from '../components/Careers/careers'

const Careers = () => (
    <Layout>
        <Seo title="Career Opportunities" />
        <Career />
    </Layout>
)

export default Careers
